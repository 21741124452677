let scrollElm = (function () {
  if (document.scrollingElement) return document.scrollingElement;
  if (navigator.userAgent.indexOf("WebKit") != -1) return "body";
  return document.documentElement;
})();

let scrollBtn = $(".top-of-page");
let hamburgerBtn = $(".header-hamburger");
let closeBtn = $(".header-close");
let scrollVal;
let isScrolling = 0 ;
let isScrollingTime;
let windowHeight = 0;
let contentHeight = 0;
let footerHeight = 0;


$(window).on("scroll touchmove", scrollCheck);
$(window).on("load", function() {
  scrollCheck();
  $("body").addClass("is-start");

  if ($(".mv-slide").length) {
    mvScroll();
  }
});

function scrollCheck() {
  scrollVal = $(window).scrollTop();
  windowHeight = window.innerHeight ? window.innerHeight : $(window).height();
  contentHeight = $("body").height();
  footerHeight = $(".footer").height();
  if (scrollVal > 100) {
    scrollBtn.addClass("is-show");
  } else if (scrollVal < 100) {
    scrollBtn.removeClass("is-show");
  }
  if (scrollVal + windowHeight > contentHeight - footerHeight + 100) {
    scrollBtn.addClass("is-bottom");
  } else if (scrollVal + windowHeight < contentHeight - footerHeight + 100) {
    scrollBtn.removeClass("is-bottom");
  }
  if (scrollVal > windowHeight * 0.8) {
    $("body").addClass("is-scroll-oneScreen");
  } else {
    $("body").removeClass("is-scroll-oneScreen");
  }
}

window.addEventListener("scroll", function () {
	isScrolling = 1 ;
	clearTimeout( isScrollingTime ) ;
	isScrollingTime = setTimeout( function () {
		isScrolling = 0 ;
	}, 300 ) ;
} ) ;

scrollBtn.on("click",function () {
  if (isScrolling == 0) {
    window.scroll({ top: 0, behavior: "smooth" });
  }
  return false;
});

hamburgerBtn.on("click",function () {
  const target = $(".header-toggle");
  if (!target.is(":animated")) {
    target.slideToggle(250);
    $("body").toggleClass("is-header-open");
    hamburgerBtn.find(".text").text($("body").hasClass("is-header-open") ? "CLOSE" : "MENU");
    if ($("body").hasClass("is-header-open")) {
      scrollSet.stopScroll();
    } else {
      scrollSet.removeScroll();
    }
  }
});

closeBtn.on("click",function () {
  const target = $(".header-toggle");
  if (!target.is(":animated")) {
    target.slideUp(250);
    $("body").removeClass("is-header-open");
    hamburgerBtn.find(".text").text("MENU");
    scrollSet.removeScroll();
  }
});

let scrollSet = {
  scrollTop: 0,
  scrollTop2: 0,
  scrollbarW : 0,
  stopScroll: function() {
    scrollSet.scrollbarW = window.innerWidth - document.body.clientWidth;
    scrollSet.scrollTop2 = scrollVal;
    $("body").css({
      "padding-right": scrollSet.scrollbarW + "px"
    }).addClass("is-scrollStop");
    $(scrollElm).css({
      'height': '100vh',
      'height': 'calc(var(--vh, 1vh) * 100)',
      'left': '0',
      'overflow': 'hidden',
      'position': 'fixed',
      'top': (scrollSet.scrollTop2 * -1) + "px",
      'width': '100vw',
    });
  },
  removeScroll: function() {
    $("body").css({
      "padding-right": ""
    }).removeClass("is-scrollStop");
    $(scrollElm).css({
      'height': '',
      'left': '',
      'overflow': '',
      'position': '',
      'top': '',
      'width': '',
      'min-width': ''
    });
    $(scrollElm).scrollTop(scrollSet.scrollTop2);
  }
}

// breakpoint
const mediaQueryList = window.matchMedia('(min-width: 769px)');
mediaQueryList.addEventListener("change", listener);
listener(mediaQueryList);
function listener(event) {
  if (event.matches) {
  // PC
    $("body").removeClass("is-header-open");
    hamburgerBtn.find(".text").text("MENU");
    scrollSet.removeScroll();
  } else {
  // SP
  }
}

function mvScroll() {
  let $slider = $(".mv-slide");
  let counter = 0;
  let max = $slider.find("img").length;
  $slider.find("img").eq(0).addClass("is-current");
  setInterval(function() {
    $slider.find("img").eq(0).removeClass("is-init");
    counter++;
    if (counter >= max) {
      counter = 0;
    }
    $slider.find(".is-current.is-fadeout").removeClass("is-fadeout").removeClass("is-current");
    $slider.find(".is-current").addClass("is-fadeout");
    $slider.find("img").eq(counter).addClass("is-current")
  }, 8000);
}

//navi current
let nowUrl = location.pathname;
if (nowUrl.includes("/apply/form/")) {
  nowUrl = "/apply/form/";
}
if (nowUrl.includes("/contact/")) {
  nowUrl = "/contact/";
}
if (nowUrl.includes("/news/")) {
  nowUrl = "/news/";
}
if (nowUrl.includes("/case/")) {
  nowUrl = "/case/";
}

let gNavi = $(".header-toggle .header-navi a");
gNavi.each(function() {
  let naviUrl = $(this).attr('href');
  if (naviUrl == nowUrl) {
    $(this).addClass("is-current");
  }else {
    $(this).removeClass("is-current");
  }
});

$(document).on("click",".form-consent-checkbox #conf_policy",function(){
  if (this.checked) {
    $(".form-consent-submit").removeClass("is-disabled");
  } else {
    $(".form-consent-submit").addClass("is-disabled");
  }
});